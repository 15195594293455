<template>
  <div>
    <v-data-table
      disable-sort
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
    >
      <template slot="top">
        <div class="d-flex justify-end pb-3 mr-4">
          <v-btn color="primary" @click="popupShow=true">新增推荐网址</v-btn>
        </div>
      </template>
      <template #item.profilePhotoUrl="{item}">
        <v-img :src="item.profilePhotoUrl" width="30" height="30" />
      </template>
      <template #item.operate="{item}">
        <a class="operate-btn mr-3" @click="edit(item)">编辑</a>
        <a class="operate-btn" @click="remove(item)">删除</a>
      </template>
    </v-data-table>
      <!-- :sureBtn="!isFourUrl" -->
    <Popup
      v-model="popupShow"
      @onSave="onSave"
      :title="isCreate?'添加推荐网址':'编辑推荐网址'"

    >
      <!-- <v-alert dense type="info" v-if="isFourUrl">只允许添加四个网址，可以修改或者删除别的网址</v-alert> -->
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field
              dense
              label="网址名称*"
              v-model="params.websiteName"
              :rules="[v => !!v || '请输入网址名称']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              label="网址icon*"
              v-model="params.websiteIcon"
              :rules="[v => !!v || '请输入网址icon']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              label="网址url*"
              v-model="params.websiteUrl"
              :rules="[v => !!v || '请输入网址url']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              label="网址描述*"
              v-model="params.websiteDescribe"
              :rules="[v => !!v || '请输入网址描述']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      loading: false,
      popupShow: false,
      params: {
        websiteName: '',
        websiteIcon: '',
        websiteUrl: '',
        websiteDescribe: ''
      }
    }
  },
  watch: {
    popupShow(v) {
      if (!v) {
        this.params = this.$options.data().params
        this.$refs.form.reset()
      }
    }
  },
  created() {
    this.loadApp()
  },
  computed: {
    headers() {
      return [
        { text: '网址名称', value: 'websiteName' },
        { text: '网址icon', value: 'websiteIcon' },
        { text: '网址url', value: 'websiteUrl' },
        { text: '网址描述', value: 'websiteDescribe' },
        { text: '操作', value: 'operate' }
      ]
    },
    // isFourUrl() {
    //   return this.isCreate && this.items.length > 3
    // },
    isCreate() {
      return !this.params.id
    }
  },
  methods: {
    async loadApp() {
      this.loading = true
      const res = await this.$post('/adminWebsite/queryAll', {}, false)
      this.loading = false
      console.log(res)
      if (res.code !== 200) return
      this.items = res.data
    },
    edit(item) {
      this.params = this.$util.clone(item)
      this.popupShow = true
      console.log(this.params.websiteIcon)
    },
    async remove(item) {
      this.$cux.asyncOperation({
        icon: 'warning',
        text: '确定删除该网址吗?',
        preConfirm: async () => {
          const res = await this.$post('/adminWebsite/delete', { id: item.id })
          const isSuccess = res.code === 200
          this.$cux
            .alert({
              text: isSuccess ? '删除成功' : '删除失败',
              icon: isSuccess ? 'success' : 'error',
              timer: 1000
            })
            .then(() => {
              if (isSuccess) {
                this.loadApp()
              }
            })
        }
      })
    },
    async onSave() {
      console.log(this.params)
      const validate = await this.$refs.form.validate()
      if (!validate) return this.$toast('请完善表单信息')
      const res = this.isCreate ? await this.$post('/adminWebsite/create', this.params) : await this.$post('/adminWebsite/update', this.params)
      console.log(res)
      if (res.code !== 200) return
      this.loadApp()
      this.$toast(this.isCreate ? '新增成功' : '修改成功')
      this.popupShow = false
    }
  }
}
</script>
